/*
 * @Descripttion:  中文配置表
 */
export default {
    lsmc: {
        title: '制造云驾驶舱',
        operate: '近30天运营指标',
        slabTotal: '近30天消耗大板量',
        cellTotal: '近30天产出工程板量',
        cut: '近30天切割任务占比',
        deviceTotal: '近30天设备切割量',
        cutTotal: '平台近30天切割量',
        slab: '大板',
        cell: '工程板',
        cutRate: '切割占比',
        date: '日期',
        word: '增长量',
        word1: '增',
        word2: '长',
        word3: '量',
        orgNum: '企业总数',
        orgNew: '新增企业',
        userNum: '平台用户数',
        userNew: '新增用户',
        orgUserNum: '企业用户数',
        orgUserNew: '新增企业用户',
        deviceNum: '机台总数',
        deviceNew: '新增机台',
        cloud: '云端任务',
        local: '本地任务',
        device: '设备',
        cutNum: '切割量'
    }
}

/*
 * @Descripttion: 国际化配置文件
 * @Author: AXiang
 * @Date: 2019-10-30 15:06:01
 * @LastEditors: AXiang
 * @LastEditTime: 2019-10-30 20:50:26
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'
Vue.use(VueI18n)

// 详见http://kazupon.github.io/vue-i18n/zh/started.html
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
}
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // 如果没有选择语言，默认中文
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'zh'
}
const i18n = new VueI18n({
  // 设定语种
  // 选项: en | zh
  locale: getLanguage(),
  // 设置区域信息
  messages
})

export default i18n

import Vue from 'vue'
import Router from 'vue-router'
// const Login = r => require.ensure([], () => r(require('./login/index')), 'Login');
const Index = r => require.ensure([], () => r(require('./index/index')), 'Index');
// const Mine = r => require.ensure([], () => r(require('./mine/index')), 'Mine');
const Lsdc = r => require.ensure([], () => r(require('./lsdc/lsdc')), 'Lsdc');
const Dbsc = r => require.ensure([], () => r(require('./dbsc/dbsc')),'Dbsc')
const Login = r => require.ensure([], () => r(require('./login/index')), 'Login');

const originalReplace = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        // {
        //     path: '/login',
        //     name: 'Login',
        //     component: Login,
        //     meta: {
        //         auth: false
        //     }
        // },
        // {
        //     path: '/mine',
        //     name: 'Mine',
        //     component: Mine,
        //     meta: {
        //         auth: true
        //     }
        // },
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: {
                auth: false
            }
        },
        {
            path: '/lsmc',
            name: 'lsmc',
            component: Index,
            meta: {
                auth: false
            }
        },
        {
            path: '/lsdc',
            name: 'Lsdc',
            component: Lsdc,
            meta: {
                auth: false
            }
        },
        {
            path: '/dbsc',
            name: 'Dbsc',
            component: Dbsc,
            meta: {
                auth: false
            }
        },
    ]
})
// router.beforeEach((to, from, next) => {
//     let token = localStorage.getItem('userToken')
//     if(to.meta.auth) {
//         if(token) {
//             next()
//         } else {
//             next( {
//                 path: '/login'
//             })
//         }
//     } else {
//         next()
//     }
// })

export default router

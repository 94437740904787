/*
 * @Descripttion:  英文配置表
 */
export default {
    lsmc: {
        title: 'Cloud factory cockpit',
        operate: 'Operating indicators',
        slabTotal: 'Large plate consumption',
        cellTotal: 'Engineering board output',
        cut: 'Proportion of cutting tasks',
        deviceTotal: 'Equipment cutting volume',
        cutTotal: 'Platform cutting volume',
        slab: 'slab',
        cell: 'cell',
        cutRate: 'Cutting proportion',
        date: 'date',
        word: 'growth',
        word1: 'growth',
        word2: '',
        word3: '',
        orgNum: 'Total enterprises',
        orgNew: 'New enterprises',
        userNum: 'Platform user',
        userNew: 'New user',
        orgUserNum: 'Enterprise users',
        orgUserNew: 'New enterprise users',
        deviceNum: 'Total machines',
        deviceNew: 'New machines',
        cloud: 'Cloud mission',
        local: 'Local tasks',
        device: 'device',
        cutNum: 'Total Cutting'
    }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {currency} from './utils/currency'
Vue.filter("currency",currency);
import animated from 'animate.css'
Vue.use(animated)
import i18n from './lang' // 国际化
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })
import './utils/remConfig'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
